module controllers {
    export module master {
        interface IMasterDataScope extends ng.IScope {
        }

        export class masterDataCtrl {
            static $inject = ["$scope", '$rootScope', 'generalService', '$q', "menuService", "bsLoadingOverlayService", '$state', "$stateParams"];
            constructor(private $scope: IMasterDataScope, private $rootScope: interfaces.applicationcore.IRootScope, public generalService: interfaces.applicationcore.IGeneralService,
                private $q: ng.IQService, private menuService: interfaces.applicationcore.IMenuService, private bsLoadingOverlayService,
                private $state: ng.ui.IStateService, private $stateParams: ng.ui.IStateParamsService) {
            }

            public loadItems() {
                return this.menuService.getGTSConnectMenuItems().query({ parentId: this.$stateParams["parentId"] }, (resultList) => {
                }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    }).$promise;
            }

        }

        angular.module("app").controller("masterDataCtrl", controllers.master.masterDataCtrl);
    }
}